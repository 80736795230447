import React from 'react'
import Type from "../Type";
import Button from "../Button";
import eventConstants from "../../global/eventConstants";
import {isAndroid, isDesktop, isIOS} from "react-device-detect";
import {FaAndroid, FaApple} from "react-icons/fa";
import styled from "styled-components";

const AndroidIcon = styled(FaAndroid)`
    padding-right: 10px;
`

const AppleIcon = styled(FaApple)`
    padding-right: 10px;
`

const DownloadButton = (props) => {

    return (
        <Type variant="div" tl>
            {(isIOS || isDesktop) &&
                <Button w_100 w_70_m w_70_l pa0 mb2
                        {...props}
                        href="https://bnc.lt/IPig/83sUGgL57M"
                        link
                        onClick={() => {
                            track(eventConstants.common.visitedAppStore, {
                                [eventConstants.common.appTypeProp]: eventConstants.common.appType.iOS
                            })
                        }}
                >
                    <AppleIcon/> Download for iOS
                </Button>
            }
            {/* Android Button */}
            {(isAndroid || isDesktop) &&
                <Button w_100 w_70_m w_70_l center pa0
                        {...props}
                        href="https://bnc.lt/IPig/p0NveowSjH"
                        link
                        onClick={() => {
                            track(eventConstants.common.visitedAppStore, {
                                [eventConstants.common.appTypeProp]: eventConstants.common.appType.android
                            })
                        }}
                >
                    <AndroidIcon/> Download for Android
                </Button>
            }
        </Type>
    )
}

export default DownloadButton;
