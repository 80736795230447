import React from 'react';
import styled, {css} from "styled-components";
import Type from "../Type";
import LazyLoad from "react-lazyload";
import StyledImage from "../StyledImage";
import {isMobile} from "react-device-detect";

const BlockWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 499px) {
    flex-direction: column;
    align-items: center;
  }
  

  ${props =>
    props.reverse &&
    css`
      @media only screen and (min-width: 500px) {
        flex-direction: row-reverse;
      }
    `};
`

const BlockContent = styled.div`
  width: ${props => props.isMain ? '70%' : '60%'};
  @media only screen and (min-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    align-items: top;
    justify-content: ${props => props.isMain ? 'start': 'center'};
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    text-align: ${props => props.reverse ? 'right': 'left' }
  }
`

const BlockAside = styled.div`
  line-height: 0; */
  display: block;
  width: ${props => props.isMain ? '25%' : '35%'};
  @media only screen and (max-width: 500px) {
    width: 50%;
  }
`

const Block = props => (
    <BlockWrapper {...props}>
        <BlockAside {...props}>
            {
                props.customAside && !(!!props.hideImageOnMobile && isMobile) && (
                    <LazyLoad offset={100} once>
                        <StyledImage width={350} src={props.imgPath}/>
                    </LazyLoad>
                )
            }
        </BlockAside>
        <BlockContent {...props}>
                {props.heading ? (
                    <Type variant="h2" {...props.heading} f3 lh_copy fw6 dark_gray mt0 mb1>
                        {props.heading.data}
                    </Type>
                ) : null}
                {props.content ? (
                    <>
                        <Type variant="p" {...props.content} lh_copy gray>
                            {props.content.data}
                        </Type>
                        {props.content.extraData}
                    </>
                ) : null}
                {!props.customAside ? <div>{props.children}</div> : null}
        </BlockContent>
    </BlockWrapper>
)

export default Block;
