import React, {Component} from 'react'
import Layout from '../components/Layout'
import Section from '../components/Section'
import Wrapper from '../components/Wrapper'
import Type from '../components/Type'
import {defineMessages, injectIntl} from 'gatsby-plugin-intl'
import {track as trackEvent} from '../global/analytics'
import eventConstants from '../global/eventConstants'
import Block from "../components/Block/Block";
import {FaAngleDown} from "react-icons/fa";
import {isDesktop, isMobile} from "react-device-detect";
import WatchVideoButton from "../components/WatchVideoButton/WatchVideoButton";
import IphoneImage from "../../static/assets/img/app/iphone_new.png";
import DownloadButton from "../components/DownloadButton/DownloadButton";
import IphoneListView from "../../static/assets/img/app/iphone-list-view.png";
import IphoneUnsubscribe from "../../static/assets/img/app/iphone-unsubscribe.png";
import IphoneRollup from "../../static/assets/img/app/iphone-rollup_new.png";
import IphoneSubscriptions from "../../static/assets/img/app/iphone-subscriptions.png";
import IphoneRichText from "../../static/assets/img/app/iphone-rich-text.png";

export const messageDescriptors = defineMessages({
    title: {
        id: 'pages.app.title', defaultMessage: 'A whole new experience',
    }, heading: {
        id: 'pages.app.heading', defaultMessage: "Our revolutionary email experience, on your phone."
    }, subHeading: {
        id: 'pages.app.subHeading',
        defaultMessage: 'Unsubscribe from unwanted emails and consolidate the emails you want to keep — all with one swipe.'
    }, watchVideo: {
        id: 'pages.app.watchVideo', defaultMessage: 'Watch the video'
    }, streamliningText: {
        id: 'pages.app.streamliningText', defaultMessage: 'Streamlining your inbox has never been this easy'
    }, rollupSectionTitle: {
        id: 'pages.app.rollupSectionTitle', defaultMessage: 'View your Rollup'
    }, rollupSectionContent: {
        id: 'pages.app.rollupSectionContent',
        defaultMessage: 'The Rollup is a once-daily digest containing all of the subscriptions you want to keep, but don’t need right away. The subscriptions you add to your Rollup won’t be sent to your inbox directly, but will be included in this once-daily Rollup, delivered at the time of your choosing: morning, afternoon, or evening.'
    },
})


const APP_DATA = [
    {
        id:0,
        isMain: true,
        heading:{
            data:['The ', <span style={{color: '#f7931d'}}>power</span>, ' of Unroll',<span style={{color: '#00bcbb'}}>.</span>,'Me right in your ', <span style={{color: '#00bcbb'}}>pocket</span>, '.' ],
            fontSize: [48, 56],
            textColor: '#404040'
        },
        content:{
            data: ['The Unroll.Me app is packed with everything you need to regain control of your inbox.'],
            fontSize:[26, 30],
            textColor: '#404040',
            extraData: <WatchVideoButton bgColor={'#00bcbb'} borderRadius={30} textColor={'#fff'} />
        },
        hideImageOnMobile: true,
        bgColor: '#fff',
        btnColor: '#00bcbb',
        imgPath: IphoneImage
    },
    {
        id:1,
        heading:{
            data:[ 'New ', <span style={{color: '#00bcbb'}}>List View</span>],
            fontSize: [40, 48],
            textColor: '#ffffff'
        },
        content:{
            data: ['Making it easier to view your subscriptions, the new list view shows you all subscriptions in your inbox and allows you to easily search and act on your subscriptions to your heart’s desire.'],
            fontSize:[21, 26],
            textColor: '#ffffff',
            extraData: <DownloadButton bgColor={'#00bcbb'} borderRadius={30} textColor={'#fff'}/>
        },
        bgColor: '#404040',
        imgPath: IphoneListView
    },
    {
        id:2,
        heading:{
            data:[ (<span style={{color: '#c83c3c'}}>Unsubscribe</span>), ' in Bulk'],
            fontSize: [40, 48],
            textColor: '#404040'
        },
        content:{
            data: ['The wait is finally over! You can now select ' +
            'multiple (or all) subscriptions and bulk ' +
            'unsubscribe, keep, or rollup.'],
            fontSize:[21, 26],
            textColor: '#404040',
            extraData: <DownloadButton bgColor={'#c83c3c'} borderRadius={30} textColor={'#fff'} />
        },
        bgColor: '#fff',
        imgPath: IphoneUnsubscribe
    },
    {
        id:3,
        heading:{
            data:[ (<span style={{color: '#f7931d'}}>Rollup</span>), ' Revamped'],
            fontSize: [40, 48],
            textColor: '#404040'
        },
        content:{
            data: ['Don’t want to unsubscribe but also don’t want to keep a subscription in your inbox? No worries! Roll it up, and Unroll.Me will notify you whenever you have new emails in your rollup.'],
            fontSize:[21, 26],
            textColor: '#404040',
            extraData: <DownloadButton bgColor={'#f7931d'} borderRadius={30} textColor={'#fff'}  />
        },
        bgColor: 'rgba(247, 147, 29, 0.25)',
        imgPath: IphoneRollup
    },
    {
        id:4,
        heading:{
            data:[ (<span style={{color: '#fff'}}>Edit</span>), ' Subscriptions'],
            fontSize: [40, 48],
            textColor: '#404040'
        },
        content:{
            data: ['Unsubscribed from something but want to keep it instead? Tap the subscriptions tab and make edits to your subscriptions with ease. Nothing is permanent…make changes as you wish.'],
            fontSize:[21, 26],
            textColor: '#404040',
            extraData: <DownloadButton bgColor={'#404040'} borderRadius={30} textColor={'#fff'}/>
        },
        bgColor: 'rgba(64, 64, 64, 0.25)',
        imgPath: IphoneSubscriptions
    },
    {
        id:5,
        heading:{
            data:[ (<span style={{color: '#1159b6'}}>Rich Text</span>), ' Emails'],
            fontSize: [40, 48],
            textColor: '#404040'
        },
        content:{
            data: ['All subscriptions in Unroll.Me can display a preview of the email in Rich Text allowing you to quickly decide what to do with a subscription. That’s not all…the built-in browser will allow you to tap through links'],
            fontSize:[21, 26],
            textColor: '#404040',
            extraData: <DownloadButton bgColor={'#1159b6'} borderRadius={30} textColor={'#fff'} />
        },
        bgColor: '#fff',
        imgPath: IphoneRichText
    }
]

const track = (eventName, eventProps) => {
    trackEvent(eventName, {
        ...eventProps, Page: eventConstants.app.pageName
    })
}

class App extends Component {
    state = {
        isVideoModalOpen: false, isIphone: false, isAndroid: false, isDesktop: false
    }

    componentDidMount() {
        track(eventConstants.app.pageLoad);
        const navigatorAgent = navigator.userAgent;
        const isIphone = /iPhone OS/.test(navigatorAgent);
        const isAndroid = /Android/.test(navigatorAgent);
        const isDesktop = !isAndroid && !isIphone;

        if (!isDesktop) {
            const queryParams = new URLSearchParams(window.location.search)
            const fromQueryParam = queryParams.get("from");
            if (fromQueryParam && fromQueryParam === "mailbox") {
                track(eventConstants.common.visitedAppStore, {
                    [eventConstants.common.appTypeProp]: isAndroid ? eventConstants.common.appType.android : eventConstants.common.appType.iOS
                })
                window.location.replace(isAndroid ? "https://bnc.lt/IPig/p0NveowSjH" : "https://bnc.lt/IPig/83sUGgL57M")
            }
        }
        this.setState({
            isIphone, isAndroid, isDesktop
        })
    }

    render() {
        const {intl} = this.props
        const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
            messages[key] = intl.formatMessage(messageDescriptors[key])
            return messages
        }, {})

        return (<Layout>
            {APP_DATA.map((data, index) => {
                const reverse = isMobile && index===0 ? false : index % 2 !== 1;
                return <Section key={data.id} large bgColor={data.bgColor}>
                    <Wrapper slide={index===0} medium={index>0}>
                        <Block
                            {...data}
                            customAside
                            rtl
                            reverse={reverse}
                        >
                            {/*<IphoneApp rollup/>*/}
                        </Block>
                        {
                            index === 0 && isDesktop &&
                            <>
                                <Type variant="div" textColor={'#404040'} fontSize={[20, 20, 20]} tc pt2 b>Scroll to
                                    see what's new</Type>
                                <Type variant="div" textColor={'#404040'} fontSize={[40, 40, 40]} tc
                                      pt1><FaAngleDown/></Type>
                            </>
                        }
                    </Wrapper>
                </Section>
            })}
        </Layout>)
    }
}

export default injectIntl(App)
