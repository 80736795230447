import React, {useState} from 'react';
import styled from "styled-components";
import {FaPlay} from 'react-icons/fa'
import Type from '../Type'
import YouTubePlayer from "react-player/youtube";
import Modal from "../Modal";
import eventConstants from "../../global/eventConstants";
import {track as trackEvent} from "../../global/analytics";
import Button from "../Button";

const Player = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  max-width: 600px;
  min-width: 270px;

  @media screen and (min-device-width: 600px) {
    min-width: 500px;
  }

  @media screen and (min-device-width: 1000px) {
    min-width: 640px;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const PlayIcon = styled(FaPlay)`
    padding-right: 10px;
`

const track = (eventName, eventProps) => {
    trackEvent(eventName, {
        ...eventProps,
        Page: eventConstants.app.pageName
    })
}

const WatchVideoButton = (props) => {
    const [isVideoModalOpen, setIsVideoOpen] = useState(false);
    const handleVideoModal = () => {
        if (!isVideoModalOpen) {
            track(eventConstants.app.videoLinkClicked)
        }
        setIsVideoOpen(!isVideoModalOpen)
    }

    return (
        <Type variant="div" tl>
            <Button
                {...props}
                onClick={handleVideoModal}
                link
                w_100 w_70_m w_70_l center pa0
            >
                <PlayIcon/> Watch the video
            </Button>
            <Modal
                isActive={isVideoModalOpen}
                closeModal={handleVideoModal}
                maxWidth="680px"
            >
                <Player>
                    <YouTubePlayer
                        url="https://www.youtube.com/embed/z9BLdfjHphs"
                        width="100%"
                        height="100%"
                        className="react-player"
                        playing
                        config={{
                            youtube: {
                                playerVars: {
                                    origin: typeof window !== 'undefined' ? window.location.origin : ''
                                }
                            }
                        }}
                    />
                </Player>
            </Modal>
        </Type>
    )
}

export default WatchVideoButton;
